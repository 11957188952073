import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Metadata from "../components/metadata"

import { Helmet } from "react-helmet"

const CollarsPage = () => (
  <Layout>
      <Metadata title="Home" description="This is my home page" />
 <div id="lp-header-announcement" >
     <span>YOUR CAT'S NEW FAVORTIE COLLAR</span>
     <a>SHOP NOW</a>
     </div>   
<div class="headerMain">
    <div class="block">
    
    <img id="logo" src="//d5kjiuimbly4j.cloudfront.net/pawsomeco_collars/pawsome_couture_2022.jpg"/>
  </div>
</div>
<div id="banner">
    <div class="block">
    <div id="banner-heading"> 
        <h1>5 Reasons Cat Owners <br/> Are Switching To <br/>These Collars</h1>
        <ul>
        <li>95% of Missing Cats Never Return</li>
        <li>ID is Your Cat's Ticket Home</li>
        <li>Vet Approved Collars + Tags</li>  
        </ul>
        <a id="headerBtn" class="a_button" href="https://www.pawsomecouture.com/collections/cat-collars"><button>SHOP NOW</button></a>

    </div>
    </div>
</div>

<div id="banner_mob">
    <div class="block">
    <div id="banner-mob-heading"> 
        <h1>5 Reasons Cat Owners <br/> Are Switching To <br/>These Collars</h1>
        <ul>
        <li>95% of Missing Cats Never Return</li>
        <li>ID is Your Cat's Ticket Home</li>
        <li>Vet Approved Collars + Tags</li>  
        </ul>
        <a id="headerBtn" class="a_button" href="https://www.pawsomecouture.com/collections/cat-collars"><button>SHOP NOW</button></a>
    </div>
    </div>

</div>

<div id="mainContent" class="block">
  
        <div class="img_left_text_grid">
                <div class="img_left">
                <img src="//d5kjiuimbly4j.cloudfront.net/pawsomeco_collars/missing.jpg"/>
                </div>
                <div class="img_left_text_content">
                    <h1>1. Missing Cats</h1>
                    <span>
                    Studies show that only 2 to 5 percent of the millions of cats entering shelters are reunited. The absence of a simple I.D. tag would serve most feline's one-way ticket home. Simple changes make such a difference and are especially important to outdoor cats. </span>
                </div>

        </div>

            <div class="img_left_text_grid">
                <div class="img_left order2">
                <img src="//d5kjiuimbly4j.cloudfront.net/pawsomeco_collars/door.jpg"/>
                </div>
                <div class="img_left_text_content order1">
                    <h1>2. Indoor Cats At Risk </h1>
                    <span>
                    It can happen to any cat. A door left open for just a few tragic seconds can be an indoor cat’s gateway to the streets. Unfamiliar to the outdoors, cats do panic and bolt resulting in anxious hours alone. Appropriate ID is vital yet often overlooked. We engrave beautiful cat charm tags for both your cat's name and contact information.
                    </span>
                </div>

            </div>

            <div class="img_left_text_grid">
                <div class="img_left">
                <img class="customer_photo" src="//d5kjiuimbly4j.cloudfront.net/pawsomeco_collars/vat.jpg"/>
                </div>
                <div class="img_left_text_content">
                    <h1>3. Vet Approved Buckle & Plan</h1>
                    <span>
                    Fortunately, our breakaway safety buckle snaps open if your cat ever gets stuck. Vets strong discourage the use of non-safety buckle collars. Here at Pawsome Couture, our collars are designed to simply open which is based on weight and tension.  
                 </span>
                </div>
        </div>
        <div class="img_left_text_grid">
                <div class="img_left order2">
                    <img src="//d5kjiuimbly4j.cloudfront.net/pawsomeco_collars/sleep.jpg"/>
                </div>
                <div class="img_left_text_content order1">
                    <h1>4. Cats Approve</h1>
                    <span>
                    Super lightweight band and beautiful double-leather materials. Your cat will be so awestruck by the smell of our natural leather they won't even know they're wearing a collar. Both leather and natural cotton versions are available. Luxury versions for the fancy felines.
                </span>
                 </div>

            </div>


        <div class="img_left_text_grid">
                <div class="img_left">
                    <img src="//d5kjiuimbly4j.cloudfront.net/pawsomeco_collars/kian2.jpg"/>
                </div>
                <div class="img_left_text_content">
                    <h1>5. Premium Everything</h1>
                    <span>
                    Our collars have gone through rigorous testing resulting in the best collar we could design. The reviews do speak for themselves. Choose from the tasteful color tones with sleek finishes, resulting in a superior collar. Combine with our cute engraved fish, star, or heart charms.
                    </span>
                </div>

               
        </div>

          
  
    </div>
    <div class="full-width pink">
        <div class="block pink">
        <center><h1>Cat Parents Are Obsessed</h1></center>
        <div id="reviews">
            <div class="review">
            
                <img class="customer_photo" src="//d5kjiuimbly4j.cloudfront.net/pawsomeco_collars/lacy.jpg"/>
                <span>My cat loves this collar! I bought him a different one, and he refused to wear it. I bought the Pawsome collar, and he keeps it on with no trouble. This is a beautiful collar, and we are so happy with this purchase.</span>
                <span class="review-stars">★★★★★</span>
           <span class="customer-name">- Danartra L.</span>
            </div>
            <div class="review">
                <img class="customer_photo" src="//d5kjiuimbly4j.cloudfront.net/pawsomeco_collars/pup.jpg"/>
                <span>This collar is great quality and truly lives up to its luxury name. The finishes and stitching are great. The breakaway function also works great and isn't too stiff like the cheap kmart collars. Worth every penny! </span>
                <span class="review-stars">★★★★★</span>
           <span class="customer-name">- Sarah B.</span>
            </div>
            <div class="review">
            <img class="customer_photo" src="//d5kjiuimbly4j.cloudfront.net/pawsomeco_collars/fletcher.jpg"/>
                <span> This is the only collar I’ve had that my cats have not absolutely destroyed or somehow made look old and frayed after a few days of wear. 
2 weeks in and these still look new! Definitely recommend!</span>
                <span class="review-stars">★★★★★</span>
           <span class="customer-name">- Julie H.</span>
            </div>

        </div>
        
        </div>
    </div>

    <div  class="block">

    <div id="cta" class="img_left_text_grid">
                <div class="img_left order2">
                         
                <img src="//d5kjiuimbly4j.cloudfront.net/pawsomeco_collars/milo.jpg"/>
                         </div>
                <div class="img_left_text_content order1">
                    <h2>Meet Pawsome Couture</h2>
                    <h3>Where Safety Meets Style</h3>
                    
                    <span>
                    Missing cats can be stressful. Make it a bit easier with our beautiful Collar & ID combo. </span>
                    <br />
                  <span>Hundreds of 5-star reviews, 250,000+ customers, high praise from industry experts, and beautiful reviews - you are in good hands.  </span>
                   <span id="mob_cta"><em></em>Up to 30% off your first collar order!</span>
                    <a class="a_button" href="https://www.pawsomecouture.com/collections/cat-collars"><button>SHOP OUR COLLARS</button></a>
                </div>

            </div>

        
        </div>
  </Layout>

)

export default CollarsPage
